import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import './PurposeHero.scss'

const QuestImg = 'https://d1syj4d8txnu77.cloudfront.net/SubHero/Quest.png'

export default () => (
  <div className="purpose-hero">
    <div className="wrapper">
      <div className="row">
        <div className="col-md-4">
          <StaticImage className="img" src={QuestImg} alt="Quest" imgStyle={{ objectFit: 'contain' }} />
        </div>
        <div className="col-md-8">
          <h2>Why VR + AI?</h2>
          <h4>
            The fusion of virtual reality and AI-powered avatars represents a groundbreaking advancement. Now,
            individuals can reprogram their fears and sharpen their skills through repeated exposure to simulated
            speaking scenarios.
            <br /> <br />
            Think of it as a flight simulator—but for your voice.
          </h4>
        </div>
      </div>
    </div>
  </div>
)
