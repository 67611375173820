import React from 'react'

import './RecordPlayback.scss'

const WebsitePlaybackVideo = 'https://d1syj4d8txnu77.cloudfront.net/RecordPlayback/Website_Playback.mp4'

export default class RecordPlayback extends React.Component {
  constructor(props) {
    super(props)
    this.videoRef = ''
  }

  componentDidMount() {
    this.videoRef.play()
  }

  render() {
    return (
      <div className="row record-playback">
        <div className="col-md-6 order-md-2">
          <h3 className="show-on-mobile">Recording & Playback</h3>
          <div className="headset-wrap">
            <video
              ref={(ref) => {
                this.videoRef = ref
              }}
              muted
              loop
              playsInline
              src={WebsitePlaybackVideo}
            />
          </div>
        </div>
        <div className="col-md-6 order-md-1">
          <h3 className="hide-on-mobile">Recording & Playback</h3>
          <h4>
            Practice sessions are recorded in 3D, enabling you to watch your performance from every angle and identify
            areas for improvement.
          </h4>
        </div>
      </div>
    )
  }
}
