import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'gatsby'

import './Hero.scss'

const BannerMobileVideo = 'https://d1syj4d8txnu77.cloudfront.net/Hero/Ovation_Trailer_Website_Captions.mp4'
const Banner_Mobile_FirstFrame = 'https://d1syj4d8txnu77.cloudfront.net/Hero/Banner_Mobile_Thumbnail.jpg'

const Hero = () => {
  const [audioOffManaually, setAudioOffManually] = useState(true)
  const videoRef = useRef(null)

  useEffect(() => {
    const handleScroll = () => {
      if (videoRef.current) {
        const rect = videoRef.current.getBoundingClientRect()
        if (rect.top < window.innerHeight && rect.bottom >= 0) {
          videoRef.current.play()
        } else {
          videoRef.current.pause()
        }
      }
    }

    window.addEventListener('scroll', handleScroll)
    handleScroll()

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div className="hero">
      <div className="wrapper">
        <div className="row">
          <div className="col-md-6 order-md-2">
            <div className="video-wrap">
              <video
                ref={videoRef}
                className="heroVid"
                loop
                autoPlay="autoplay"
                poster={Banner_Mobile_FirstFrame}
                muted={audioOffManaually}
                playsInline
                disableRemotePlayback
                onClick={() => {
                  setAudioOffManually(!audioOffManaually)
                }}
              >
                <source src={BannerMobileVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="overlay">
                <img src={audioOffManaually ? '/T_VolumeZero_Icon.png' : '/T_VolumeUp_Icon.png'} />
              </div>
            </div>
          </div>
          <div className="col-md-6 order-md-1">
            <h2>Speak Confidently, from Virtual to Reality</h2>
            <h4>
              Speak with realistic AI avatars in VR and get AI-generated feedback about your performance.
              <br /> <br />
              Overcome speaking anxiety, practice a speech or interview, improve conversational fluency, and much more.
              <br /> <br />
            </h4>
            <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
              <Link className="signup-button" to="/contact">
                Request a Demo
              </Link>
              <a
                className="signup-button"
                target="_blank"
                href="https://www.oculus.com/experiences/quest/4510688418969375/"
              >
                Get for Meta Quest
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
