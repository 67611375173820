import React, { useRef } from 'react'
import Slider from 'react-slick'

import './Analytics.scss'

const Analytics = (props) => {
  let sliderRef = useRef(null)

  const pause = () => {
    sliderRef.slickPause()
  }

  function NextArrow(props) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{ ...style }}
        onClick={() => {
          onClick()
          pause()
        }}
      />
    )
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{ ...style }}
        onClick={() => {
          onClick()
          pause()
        }}
      />
    )
  }

  let settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 500,
    arrows: true,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnDotsHover: true,
    pauseOnFocus: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false
        }
      }
    ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    onSwipe: () => {
      pause()
    }
  }

  const { carouselImages, openLightboxWithImageIndex } = props

  const carouselAreaClick = () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'gtm-interactive-element-home-page-marketing-image-carousel-real-as-virtual'
    })
  }

  return (
    <div className="row analytics">
      <div className="col-md-6 order-md-2">
        <h3 className="show-on-mobile">AI-Powered Feedback & Analytics</h3>
        <div className="slider-wrap gtm-feature-area" onClick={carouselAreaClick}>
          <Slider ref={(slider) => (sliderRef = slider)} {...settings}>
            {carouselImages.map((img, index) => (
              <div key={`realism-${index}`} onClick={() => openLightboxWithImageIndex(index, 1)}>
                <img src={img} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="col-md-6 order-md-1">
        <div className="analytics-text-wrap">
          <h3 className="hide-on-mobile">AI-Powered Feedback & Analytics</h3>
          <h4>
            Each performance is evaluated by ChatGPT‘s latest model, providing insightful feedback to help you improve.
            <br />
            <br />
            Full transcripts are available in the app and downloadable on the web.
            <br />
            <br />
          </h4>
        </div>
      </div>
    </div>
  )
}

export default Analytics
