import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import './OvationForEducation.scss'

const image = 'https://d1syj4d8txnu77.cloudfront.net/Education/FlexibleConcurrentLicensing.jpg'

export default class OvationForEducation extends React.Component {
  render() {
    return (
      <div className="ovation-for-education">
        <div className="wrapper">
          <div className="row" style={{ alignItems: 'center' }}>
            <div className="col-md-6">
              <h2 className="show-on-mobile mobile-center">Ovation for Education</h2>
              <StaticImage
                className="img"
                src={image}
                alt="Ovation for Education"
                imgStyle={{ objectFit: 'contain' }}
              />
            </div>
            <div className="col-md-6 mobile-center">
              <h2 className="hide-on-mobile">Ovation for Education</h2>
              <h4>
                Explore our organization subscription, with features designed to help educators get set up quickly and
                empower students to achieve their full potential.
              </h4>
              <br />
              <Link className="signup-button" to="/education">
                Learn more
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
